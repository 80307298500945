



























































import { Turma } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { TurmaService } from "@/core/services/geral";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class ConfiguracaoCracha extends PageBase {
    
    valid: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    turma: Turma = new Turma();
    turmas: Turma[] = [];
    turmaService: TurmaService = new TurmaService();
    isTurmaLoading: boolean = false;

    lista: any[] = [];
    selectLista: any = [];
    loading: boolean = false;
    headers: any[] = [
        { text: "", sortable: false },
        { text: "Nome", value: "nome" },
        { text: "Tipo", value: "tipo" },
    ];
    
    filtro: any = {
        turmaId: 0,
        fundo: false,
        idAluno: "",
        idAnjo: ""
    }

    todos: boolean = false;
    alunos: boolean = false;
    anjos: boolean = false;

    $refs!: {
        form: HTMLFormElement
    }

    @Watch('filtro.turmaId')
    WatchTurma(){
        
        if(!this.filtro.turmaId){
            this.lista = [];
            this.selectLista = [];
            return;
        }            

        this.turmaService.ObterPorId(this.filtro.turmaId, "Alunos.Aluno, Secretarios.Usuario").then(
            res => {
                this.turma = res.data;
            },
            err =>{
                this.$swal('Aviso',err.response.data,'error')
            }
        )
    }

    @Watch('alunos')
    @Watch('anjos')
    WacthAnjos(){
        if(this.alunos == false || this.anjos == false){
            this.todos = false;
        }
        if(this.alunos && this.anjos){
            this.todos = true;
        }

        this.Popular();
    }   

    mounted() {
        this.turmaService.ListarAbertas().then(
            res=> {
                this.turmas = res.data.items
            }
        )   
    }

    TODOS(){
        if(this.todos){
            this.alunos = true;
            this.anjos = true;   
        }
        else{
            this.alunos = false;
            this.anjos = false;  
        }

        this.Popular();
    }

    Popular(){
        this.lista = [];
        this.selectLista = [];

        if(this.alunos){
            this.turma.alunos.forEach( aluno => {
                this.lista.push({
                    id: aluno.alunoId,
                    nome: aluno.aluno.nome,
                    tipo: "Aluno"
                })
            })
        }

        if(this.anjos){
            this.turma.secretarios.forEach( anjo => {
                this.lista.push({
                    id: anjo.usuarioId,
                    nome: anjo.usuario.complementar.nome,
                    tipo: "Anjo"
                })
            });
        }
    }

    GerarRelatorio(){
        
        if (this.$refs.form.validate()) {
            
            if(this.selectLista.length == 0){
                this.$swal('Aviso',"Nenhum aluno selecionado!",'warning');
            }
            else{

                let alunos = "";
                let usuarios = "";

                this.selectLista.forEach(x => {
                    if(x.tipo == "Aluno"){
                        if(alunos.length == 0){
                            alunos += x.id
                        }
                        else{
                            alunos += `, ${x.id}`;
                        }
                    }
                    else{
                        if(usuarios.length == 0){
                            usuarios += x.id
                        }
                        else{
                            usuarios += `, ${x.id}`;
                        }
                    }
                });

                let routeLink = this.$router.resolve({name:"impressaoCertificado",
                    query:{
                        turmaId: this.filtro.turmaId,
                        fundo: this.filtro.fundo,
                        idAluno: alunos,
                        idUsuario: usuarios
                    }
                });
                window.open(routeLink.href,'_blank');
            }
        }
    }

    LimparFiltros(){
        this.filtro.turmaId = 0;
        this.filtro.fundo = false;
        this.filtro.ids = [];

        this.lista = [];
        this.$refs.form.resetValidation();
    }

}
